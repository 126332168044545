import React from 'react'
import { Link } from 'react-router-dom'
import google from "../Images/Icons/google_2991148.png"
import facebook from "../Images/Icons/facebook_145802.png"
import instagram from "../Images/Icons/instagram_2111463.png"
import youtube from "../Images/Icons/youtube.png"

const Footer = () => {
  return (
    <div>

      <footer class="footer-section">
        <div class="footer-content">
          <div class="row">
            <div class="col-xl-4 col-lg-3 mb-50">
              <div class="footer-widget">
                <div class="footer-widget-heading">
                  <h3>About us</h3>
                </div>
                <div class="footer-text">
                  <p> At Dwarka School Of Music- we believe in the power of music to inspire, educate, and transform lives. We provide exceptional music education and fostering a lifelong love and appreciation for music in our students.</p>
                </div>
                <div class="footer-social-icon">
                  {/* <div class="footer-widget-heading">
                    <h3>Follow us</h3>
                  </div> */}
                  <a target='_blank' href="https://www.google.com/search?q=dwarka+school+of+music&oq=dwa&aqs=chrome.1.69i60j69i59j69i57j46i131i433i512j0i433i512j0i512j46i131i433i512j0i131i433i650j0i512j46i131i433i650.1673j0j4&client=ms-unknown&sourceid=chrome-mobile&ie=UTF-8"><img src={google} alt="google" /></a>
                  <a target='_blank' href="https://www.instagram.com/dsom_07/"><img src={instagram} alt="instagram" /></a>
                  <a target='_blank' href="https://www.facebook.com/profile.php?id=100084562365978"><img src={facebook} alt="facebook" /></a>
                  <a target='_blank' href="https://www.youtube.com/@dwarkaschoolofmusic-ma"><img src={youtube} alt="facebook" /></a>
                </div>
              </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 mb-0">
              <div class="footer-widget">
                <div class="footer-widget-heading">
                  <h3>Useful Links</h3>
                </div>
                <ul>
                  <li><Link to={"/"}>Home</Link></li>
                  <li><Link to={"/about"}>About us</Link></li>
                  <li><Link to={"/#classes"}>Classes</Link></li>
                  <li><Link to={"/our_team"}>Our Team</Link></li>
                  <li><Link to={"/gallery"}>Gallery</Link></li>
                </ul>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 mb-0">
              <div class="footer-widget">
                <div class="footer-widget-heading">
                  <h3>Contact Us</h3>
                </div>
                <div className="footer-widget-c">
                  <ul>
                    <li><svg id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m298.789 313.693c-12.738 8.492-27.534 12.981-42.789 12.981-15.254 0-30.05-4.489-42.788-12.981l-209.803-139.873c-1.164-.776-2.298-1.585-3.409-2.417v229.197c0 26.278 21.325 47.133 47.133 47.133h417.733c26.278 0 47.133-21.325 47.133-47.133v-229.198c-1.113.834-2.249 1.645-3.416 2.422z" /><path d="m20.05 148.858 209.803 139.874c7.942 5.295 17.044 7.942 26.146 7.942 9.103 0 18.206-2.648 26.148-7.942l209.803-139.874c12.555-8.365 20.05-22.365 20.05-37.475 0-25.981-21.137-47.117-47.117-47.117h-417.766c-25.98.001-47.117 21.137-47.117 47.142 0 15.085 7.496 29.085 20.05 37.45z" /></svg></li>
                    <li><svg id="Layer_3" height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 3"><path d="m30.035 22.6c-.082-.065-6.035-4.356-7.669-4.048-.78.138-1.226.67-2.121 1.735-.144.172-.49.584-.759.877a12.517 12.517 0 0 1 -1.651-.672 13.7 13.7 0 0 1 -6.321-6.321 12.458 12.458 0 0 1 -.672-1.651c.294-.27.706-.616.882-.764 1.06-.89 1.593-1.336 1.731-2.118.283-1.62-4.005-7.614-4.05-7.668a2.289 2.289 0 0 0 -1.705-.97c-1.738 0-6.7 6.437-6.7 7.521 0 .063.091 6.467 7.988 14.5 8.025 7.888 14.428 7.979 14.491 7.979 1.085 0 7.521-4.962 7.521-6.7a2.283 2.283 0 0 0 -.965-1.7z" /></svg></li>
                    <li><svg id="Layer_3" height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 3"><path d="m30.035 22.6c-.082-.065-6.035-4.356-7.669-4.048-.78.138-1.226.67-2.121 1.735-.144.172-.49.584-.759.877a12.517 12.517 0 0 1 -1.651-.672 13.7 13.7 0 0 1 -6.321-6.321 12.458 12.458 0 0 1 -.672-1.651c.294-.27.706-.616.882-.764 1.06-.89 1.593-1.336 1.731-2.118.283-1.62-4.005-7.614-4.05-7.668a2.289 2.289 0 0 0 -1.705-.97c-1.738 0-6.7 6.437-6.7 7.521 0 .063.091 6.467 7.988 14.5 8.025 7.888 14.428 7.979 14.491 7.979 1.085 0 7.521-4.962 7.521-6.7a2.283 2.283 0 0 0 -.965-1.7z" /></svg></li>
                    <li style={{ marginBottom: '0' }}><svg id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-105.6 0-191.5 85.9-191.5 191.5 0 34.4 16.2 77.8 49.4 132.7 27.6 45.6 61.8 90.9 91.9 130.8 13.9 18.4 27 35.8 37.1 50.2 3 4.3 7.9 6.8 13.1 6.8s10.1-2.5 13.1-6.8c10.1-14.4 23.2-31.8 37.1-50.2 30.1-39.9 64.2-85.2 91.9-130.8 33.2-54.9 49.4-98.3 49.4-132.7 0-105.6-85.9-191.5-191.5-191.5zm0 280c-48.8 0-88.5-39.7-88.5-88.5s39.7-88.5 88.5-88.5 88.5 39.7 88.5 88.5-39.7 88.5-88.5 88.5z" /></svg> </li>
                  </ul>
                  <ul>
                    <li><a href="mailto:contact.dsom@gmail.com" style={{ textTransform: 'lowercase' }}>contact.dsom@gmail.com
                    </a></li>
                    <li><a href='tel:918287202085'>+91-8287202085</a> </li>
                    <li><a href='tel:911147091917'>+91-11-47091917</a></li>
                    <li><a>E-1083, 1st Floor, MPS Plaza, Ramphal Chowk Rd,Sector 7, Dwarka, New Delhi, Delhi 110075</a></li>
                  </ul>
                </div>

              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 mb-0">
              <div class="footer-widget">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1751.7434092385017!2d77.071909!3d28.585169!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b1636452edb%3A0xabd7d2e066dbca55!2sDwarka%20School%20of%20Music!5e0!3m2!1sen!2sin!4v1686645993272!5m2!1sen!2sin" allowfullscreen="" loading="lazy" title='footer_map' referrerpolicy="no-referrer-when-downgrade" ></iframe>
              </div>
            </div>

          </div>
        </div>

        <div class="copyright-area">
          <div class="container">
            <div class="row">
              <div class="col-xl-4 col-lg-6 text-center text-lg-left">
                <div class="copyright-text">
                  <p>Copyright &copy; {new Date().getFullYear()}, All Right Reserved <a href="https://ahsassuredservices.com/web_design_and_development">AHS</a></p>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                <div class="footer-menu">
                  <ul>
                    <li><Link to={"/privacy_policy"} >Privacy Policy</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>



    </div>
  )
}

export default Footer
