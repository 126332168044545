import React from 'react'
import titleimg from "../Images/title shape.png"

const Title = (props) => {
    return (
        <div className='title'>
            <img src={titleimg} alt="title image" className='title1' />
            <h1>{props.h3}</h1>
            <img src={titleimg} alt="title image" className='title2' />
        </div>
    )
}

export default Title