import React from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import founder from "../Images/About/founder.webp"
import { useEffect, useState } from "react"
import Whatsapp from '../Utilities/Whatsapp'
import ReadMoreArea from '@foxeian/react-read-more';

const About = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);


  const [visible, setVisible] = useState(false);

  const buttonStyle = {
    color: "blue",
    fontSize: "1rem",
    padding: "1.5rem",
    // Add any other CSS styles as needed
  };

  return (
    <div>
      <Header />
      <Whatsapp />
      <div id="pages-about">
        <div className='pages-about'>
          <div className="pages-about-img">
          </div>
          <div className="pages-about-text">
            <h1>Welcome to  <span>Dwarka School of Music</span></h1>

            <div className='pages-about-founder'>
              <img src={founder} alt="founder" className='img-fluid' />
              <div>
                <h2>Mr. manoj kumar</h2>
                <h6>Founder</h6>
                {/* <ReadMoreArea
                  lettersLimit={900} // limit of letters (100 letters)
                >
                  "As we embark on this incredible journey of music education, I am filled with gratitude for each and every one of you who has chosen to be a part of our thriving music academy.

                  Music has always been a universal language, capable of transcending barriers and touching the depths of our souls. At Dwarka School of  Music, we strive to foster a nurturing environment where creativity flourishes and talents are honed to their fullest potential. Our mission is to provide comprehensive music education that encompasses not only technical proficiency but also a deep appreciation for the beauty and power of music.

                  We firmly believe that every individual possesses a unique musical voice, waiting to be discovered and nurtured. Whether you are a beginner, intermediate, or advanced musician, our dedicated team of instructors is committed to guiding you on your musical journey. We offer a wide range of courses tailored to suit various interests and skill levels, ensuring that there is something for everyone. <br /><br />

                  In addition to individual lessons, we also place great importance on ensemble playing and collaboration. We encourage our students to explore the joy of making music together, fostering a sense of camaraderie and teamwork. Through group performances, workshops, and concerts, we create opportunities for you to showcase your talents and share your passion with others.<br /><br />

                  As the founder of Dwarka School of Music, we are driven by a deep love for music and a desire to create a vibrant community of musicians. We are dedicated to providing you with the resources, support, and guidance necessary to unlock your musical potential. Our commitment extends beyond the confines of our physical space, as we strive to create meaningful connections and inspire a lifelong love for music.<br /><br />

                  I encourage you to embrace this incredible opportunity with an open heart and mind. Be ready to immerse yourself in the world of music, to explore new horizons, and to challenge yourself. Remember that progress comes with dedication, perseverance, and a willingness to learn from both successes and setbacks.<br /><br />

                  I would like to extend my heartfelt gratitude to our exceptional team of instructors, who bring their passion, expertise, and dedication to every lesson. Their unwavering commitment to nurturing your musical growth is the cornerstone of our academy.<br /><br />

                  Finally, I want to express my deepest appreciation to our students and their families for entrusting us with your musical education. Your presence and enthusiasm are what make Dwarka School of Music truly special. Together, let us create a harmonious symphony that will resonate for years to come."
                </ReadMoreArea> */}
                <p>As we embark on this incredible journey of music education, I am filled with gratitude for each and every one of you who has chosen to be a part of our thriving music academy.</p>

                <p>Music has always been a universal language, capable of transcending barriers and touching the depths of our souls. At Dwarka School of  Music, we strive to foster a nurturing environment where creativity flourishes and talents are honed to their fullest potential. Our mission is to provide comprehensive music education that encompasses not only technical proficiency but also a deep appreciation for the beauty and power of music.</p>

                <p> We firmly believe that every individual possesses a unique musical voice, waiting to be discovered and nurtured. Whether you are a beginner, intermediate, or advanced musician, our dedicated team of instructors is committed to guiding you on your musical journey. We offer a wide range of courses tailored to suit various interests and skill levels, ensuring that there is something for everyone.</p>

                <p> In addition to individual lessons, we also place great importance on ensemble playing and collaboration. We encourage our students to explore the joy of making music together, fostering a sense of camaraderie and teamwork. Through group performances, workshops, and concerts, we create opportunities for you to showcase your talents and share your passion with others.</p>

                <p>As the founder of Dwarka School of Music, we are driven by a deep love for music and a desire to create a vibrant community of musicians. We are dedicated to providing you with the resources, support, and guidance necessary to unlock your musical potential. Our commitment extends beyond the confines of our physical space, as we strive to create meaningful connections and inspire a lifelong love for music.</p>

                <p>I encourage you to embrace this incredible opportunity with an open heart and mind. Be ready to immerse yourself in the world of music, to explore new horizons, and to challenge yourself. Remember that progress comes with dedication, perseverance, and a willingness to learn from both successes and setbacks.</p>
                <p>I would like to extend my heartfelt gratitude to our exceptional team of instructors, who bring their passion, expertise, and dedication to every lesson. Their unwavering commitment to nurturing your musical growth is the cornerstone of our academy.</p>

                <p>Finally, I want to express my deepest appreciation to our students and their families for entrusting us with your musical education. Your presence and enthusiasm are what make Dwarka School of Music truly special. Together, let us create a harmonious symphony that will resonate for years to come.</p>



              </div>

            </div>

            <div className='pages-about-founder founder2'>
              <img src="https://res.cloudinary.com/dcimsha8g/image/upload/v1694171336/AHS%20Assured%20Services%20Pvt%20Ltd/Our%20Team/akhil-removebg-preview_l9lqws.png" alt="founder" className='img-fluid' />
              <div>
                <h2>Mr. akhil chandra</h2>
                <h6>Co-Founder</h6>
                {/* <ReadMoreArea
                  lettersLimit={900} // limit of letters (100 letters)
                >
                  "I am thrilled to join forces with Mr. Manoj Kumar and become a Co-Founder of Dwarka School of Music. My journey into the world of music has been deeply enriching, and I firmly believe in the transformative power of music education.

                  Music is more than just a skill; it's a universal language that fosters creativity, discipline, and emotional intelligence. It cultivates cognitive development, enhances social skills, and instills a lifelong appreciation for art and culture.

                  At Dwarka School of Music, we are committed to providing an exceptional learning experience for students of all ages and backgrounds. We will leverage technology and innovative teaching methods to make music education accessible, engaging, and enjoyable.<br /><br />

                  Our vision is to nurture a generation of passionate musicians who can express themselves authentically and contribute to the vibrant musical landscape of our community.<br /><br />

                  We invite you to join us on this exciting journey as we strive to make music education a cornerstone of personal and societal growth."
                </ReadMoreArea> */}
                <p> I am thrilled to join forces with Mr. Manoj Kumar and become a Co-Founder of Dwarka School of Music. My journey into the world of music has been deeply enriching, and I firmly believe in the transformative power of music education.</p>

                <p>Music is more than just a skill; it's a universal language that fosters creativity, discipline, and emotional intelligence. It cultivates cognitive development, enhances social skills, and instills a lifelong appreciation for art and culture.</p>

                <p>At Dwarka School of Music, we are committed to providing an exceptional learning experience for students of all ages and backgrounds. We will leverage technology and innovative teaching methods to make music education accessible, engaging, and enjoyable.</p>

                <p>Our vision is to nurture a generation of passionate musicians who can express themselves authentically and contribute to the vibrant musical landscape of our community.</p>

                <p>We invite you to join us on this exciting journey as we strive to make music education a cornerstone of personal and societal growth.</p>



              </div>

            </div>

            <div>
              <h1>why us</h1>


              <p><span>At Dwarka School Of Music</span>- we believe in the power of music to inspire, educate, and transform lives. We provide exceptional music education and fostering a lifelong love and appreciation for music in our students.</p>
              <p>  Our Academy is home to a team of highly skilled and passionate music educators who are committed to nurturing each student's musical talents and abilities. Whether you're a beginner or an advanced musician, our comprehensive curriculum is designed to meet the unique needs and goals of every student.</p>
              <p>  We offer a wide range of music lessons and classes for various instruments, including guitar, drums, Keyboard, Vocals and more. Our experienced instructors utilize innovative teaching methods, combining traditional techniques with modern approaches, to create a dynamic and engaging learning environment.</p>
              <p>  In addition to individual lessons, we offer ensemble and group classes, providing students with opportunities to collaborate and perform with their peers. We believe that playing music with others not only enhances musical skills but also develops teamwork, communication, and creativity.</p>
              <p> Our Music Academy is equipped with state-of-the-art facilities and resources to enhance the learning experience. We provide practice rooms, performance spaces, and access to a variety of instruments to support our students' musical growth and exploration.
                At our Academy, we recognize that music education extends beyond technical proficiency. We strive to cultivate well-rounded musicians by incorporating music theory, ear training, sight-reading, improvisation, and music history into our curriculum. Our goal is to empower students to become confident performers, knowledgeable musicians, and lifelong learners.</p>
              <p>
                We celebrate the achievements and progress of our students through regular recitals, concerts, and competitions. These events provide valuable performance opportunities, allowing students to showcase their talents and build their confidence on stage.</p>
              <p>
                We welcome students of all ages and skill levels, from young beginners to adult learners. Whether you aspire to become a professional musician or simply want to enjoy the enriching experience of playing music, our Music Academy is the perfect place to embark on your musical journey.</p>
              <p>     Join us at Dwarka School of Music and discover the joy of making music! Contact us today to schedule a trial lesson and begin your musical adventure.</p>


            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default About
