import React from 'react'
import logo from "../Images/Logo/header-logo.png"
import { Link } from 'react-router-dom'

const Header = () => {


  return (
    <div>
      <div id='header'>

        <Link to={"/"}>
          <div className="header-logo">
            <img src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691563397/Dwarka%20School%20Of%20Music/Logo/m_logo_jispoe.ico" alt="logo" />
          </div>
        </Link>
        <header className='header-navbar'>
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <a className="navbar-brand" href="#"></a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link className="nav-link" aria-current="page" to={'/'}>Home</Link>
                  </li>
                  <li className="nav-item">
                    <Link to={'/about'} className="nav-link">about us</Link>
                  </li>
                  {/* <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Classes
                    </a>
                    <ul class="dropdown-menu">
                      <li><Link class="dropdown-item" to={"/guitar"}>Guitar</Link></li>
                      <li><Link class="dropdown-item" to={"/drums"}>Drums</Link></li>
                      <li><Link class="dropdown-item" to={"/vocal"}>Vocal</Link></li>
                      <li><Link class="dropdown-item" to={"/keyboard"}>Keyboard</Link></li>
                      <li><Link class="dropdown-item" to={"/dance"}>Dance</Link></li>
                    </ul>
                  </li> */}
                  <li className="nav-item">
                    <Link to={"/#classes"} className="nav-link">classes</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={'/our_team'}>our team</a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={'/blog'}>blog</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={'/gallery'}>gallery</Link>
                  </li>
                </ul>

              </div>
            </div>
          </nav>
        </header>

      </div>
    </div>
  )
}

export default Header
