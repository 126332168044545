import React, { useState, useEffect } from 'react'
import Header from '../../Utilities/Header'
import Footer from '../../Utilities/Footer'
import image1 from "../../Images/Blog/How After-School Music and Dance Education Can Benefit Students and Parents.png"
import "./Blog.scss"

import { useParams, Link } from 'react-router-dom';

const BlogDetails = () => {

    return (
        <div>
            <Header />


            <div className=" blog-details">
                <img src={image1} alt={"How After-School Music and Dance Education Can Benefit Students and Parents"} />
                <div className="blog-card-text">
                    {/* <span className='date'>{blogs.Date}</span> */}

                    <h1>How After-School Music and Dance Education Can Benefit Students and Parents</h1>
                    <div>
                        <p>
                            After-school music and dance education offers a multitude of benefits for students and parents alike. These programs provide a creative outlet, foster social skills, and enhance overall well-being. Let's explore some of the key advantages:</p>

                        <h4>   Benefits for Students:</h4>
                        <ol>
                            <li>Creative Expression: Music and dance provide a platform for students to express themselves artistically and creatively. This can boost self-confidence and help them develop a unique sense of identity.</li>
                            <li>Cognitive Development: Engaging in music and dance activities has been shown to improve cognitive functions such as memory, problem-solving, and critical thinking.</li>
                            <li>Social Skills: These programs offer opportunities for students to interact with peers, develop teamwork skills, and build lasting friendships.</li>
                            <li>Physical Health: Music and dance are excellent forms of exercise, promoting physical fitness and overall health.</li>
                            <li>Stress Relief: Participating in these activities can help students manage stress and anxiety, leading to better emotional well-being.</li>
                        </ol>


                        <h4>  Benefits for Parents:</h4>

                        <ol>
                            <li>Quality Time: After-school programs can provide a safe and supervised environment for students to spend time after school, giving parents peace of mind.</li>
                            <li>Enrichment: Music and dance education can complement a student's academic curriculum, providing additional enrichment and learning opportunities.</li>
                            <li>Skill Development: Parents can witness their children develop valuable life skills such as discipline, dedication, and perseverance.</li>
                            <li>Community Connection: Many after-school programs are involved in community events and performances, fostering a sense of belonging and connection.</li>
                        </ol>
                        <p>   In conclusion, after-school music and dance education offers a wealth of benefits for both students and parents. By providing a creative outlet, fostering social skills, and enhancing overall well-being, these programs can have a positive and lasting impact on a child's development.</p>


                    </div>
                </div>


            </div>
            <Footer />
        </div >
    )
}

export default BlogDetails
